export default {
    data() {
        return {
            innerWidth: 0,
        }
    },
    created() {
        window.addEventListener("resize", this.resizeEventHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeEventHandler);
    },
    methods: {
        resizeEventHandler() {
            this.innerWidth = window.innerWidth;
        }
    },
    
}